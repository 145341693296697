<template>
    <div class="event">
        <LayoutAlertMessage
            v-if="eventData.event.header_messages && eventData.event.header_messages[0]"
            :info="eventData.event.header_messages[0]"
        />
        <div class="event__cover">
            <LayoutBreadcrumbs
                class="event__cover-breadcrumbs"
                :layers="breadcrumbs"
                text-color="text-black"
                margin="mt-1"
            />

            <div class="event__cover-content">
                <div v-if="eventData.event.image && eventData.event.title" class="event__cover-content-image">
                    <img
                        :src="eventData.event.image.url"
                        :alt="eventData.event.title"
                        :title="eventData.event.title"
                        loading="lazy"
                        :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                    />
                </div>
                <div class="event__cover-content-text">
                    <h1 class="event__cover-content-title">
                        <span class="emphasis">{{ h1 }}</span>
                    </h1>
                    <h2 class="event__cover-content-sub">{{ h2 }}</h2>
                </div>
            </div>
        </div>

        <main class="event__main">
            <WidgetEventStores
                v-if="
                    !eventData.event.hide_featured_stores ||
                    (eventData.featured_stores && eventData.featured_stores.length > 0)
                "
                :info="eventData.featured_stores"
                :title="$lang.pages.eventosSlug.featured_stores"
            />
            <WidgetEventDiscounts
                :info="eventData.featured_discounts"
                :title="$lang.pages.eventosSlug.featured_discounts"
            />

            <div class="event__main-discounts">
                <template v-if="existsAnyData">
                    <section>
                        <ul class="space-y-3">
                            <li v-for="(item, key) in discounts" :key="key">
                                <DCardMain :info="item" />
                            </li>
                        </ul>
                    </section>

                    <ButtonLoadMore
                        v-if="fetchButton.show"
                        :loading="fetchButton.loading"
                        :handler="loadMore"
                        class="mt-4"
                    />
                </template>
                <MiscNoResults v-else mode="discounts" :title="$lang.pages.eventosSlug.no_discounts" />
            </div>
        </main>

        <section v-if="eventData.all_stores.length" class="event__allstores">
            <h3 class="event__allstores-title">
                {{ $lang.pages.eventosSlug.enjoy }}
                {{ eventData.event.title }}
                {{ $lang.pages.eventosSlug.in_stores }}
            </h3>
            <ul class="event__allstores-list">
                <li v-for="(item, key) in eventData.all_stores" :key="key">
                    <NuxtLink
                        no-prefetch
                        :to="`${!$isCupohosting ? `/${$lang.routes.brands}` : ''}/${item.slug}`"
                        class="event__allstores-list-item"
                    >
                        <span class="event__allstores-list-item-name">{{ item.name }}</span>
                    </NuxtLink>
                </li>
            </ul>
        </section>

        <section v-if="eventData.event.contents && eventData.event.contents.length" class="event__footer">
            <template v-for="(content, index) in eventData.event.contents">
                <article
                    v-if="
                        content.content &&
                        content.title &&
                        content.content.length &&
                        content.content !== '<p></p>'
                    "
                    :key="index"
                    class="event__footer-section"
                >
                    <div class="event__footer-head">
                        <div class="event__footer-head-image" :class="content.icon" />
                        <h3 class="event__footer-head-title">
                            {{ content.title }}
                        </h3>
                    </div>
                    <div class="event__footer-description" v-html="content.content" />
                </article>
            </template>
        </section>
    </div>
</template>

<script lang="ts" setup>
import { parseISO } from 'date-fns'
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'
import { useDateFunctions } from '~/composables/DateFunctions'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'

const { defaultImages } = useVariantsDictionary()

const { currentDate } = useDateFunctions()

const defaultImage = defaultImages.event

const { $lang } = useNuxtApp()

const Route = useRoute()
const { buildHeaders, baseUrl, endpoints } = useApiClient()

const loadEvent = (params?: { page: number }) =>
    $fetch<Api.Responses.Pages.EventDetails | Api.Responses.ResourceNotFound>(
        endpoints.pages.event.path + '/' + Route.params.slug,
        {
            headers: buildHeaders(),
            baseURL: baseUrl,
            method: 'GET',
            params,
        },
    )

const { data: responseData, error } = await useAsyncData('event-page-data', () =>
    loadEvent().catch((e) => e.data),
)

if (
    error.value ||
    !responseData.value ||
    responseData.value.error ||
    responseData.value.feedback !== 'data_success'
) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const eventData = ref(responseData.value.data)

const discounts = ref(eventData.value.discounts.data)

const {
    public: {
        variant: {
            metaData: { siteName, siteOrigin },
        },
    },
} = useRuntimeConfig()

const currentDates = currentDate()

const [title, description, h1, h2] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [
        eventData.value.event.seo.title || '',
        eventData.value.event.seo.description || '',
        eventData.value.event.seo.h1 || '',
        eventData.value.event.seo.h2 || '',
    ],
)

useSeoMeta({
    title: title,
    description: description,
    ogUrl: siteOrigin + Route.path,
    ogImage: eventData.value.event.image?.url,
    ogImageSecureUrl: eventData.value.event.image?.url,
    ogTitle: title,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.eventosSlug.home,
        },
        {
            path: `/${$lang.routes.events}`,
            title: $lang.pages.eventosSlug.events,
        },
        {
            title: eventData.value.event.title || '',
        },
    ]
})

const fetchButton = reactive({
    loading: false,
    show:
        eventData.value.discounts.meta.total &&
        eventData.value.discounts.meta.current_page &&
        eventData.value.discounts.meta.last_page &&
        eventData.value.discounts.meta.total > 0 &&
        eventData.value.discounts.meta.current_page < eventData.value.discounts.meta.last_page,
})

const params = reactive({
    page: 2,
})

const existsAnyData = computed(() => {
    return !!eventData.value.discounts.data.length
})

const isExpired = (date: string | null) => {
    if (date) {
        return parseISO(date) < new Date()
    }
    return false
}
const loadMore = async () => {
    fetchButton.loading = true

    const result = await loadEvent(params)

    if (result.feedback === 'data_success') {
        discounts.value = [...discounts.value, ...result.data.discounts.data]

        const { current_page, last_page } = result.data.discounts.meta

        if (!last_page || current_page === last_page) {
            fetchButton.show = false
        } else {
            params.page = current_page + 1
        }
    }

    fetchButton.loading = false
}
</script>

<style lang="postcss" scoped>
.event {
    @apply mb-5;
    &__cover {
        &-breadcrumbs {
            @apply container;
        }
        &-content {
            @apply container mt-2 flex gap-2 text-black lg:gap-4;
            &-image {
                @apply w-[7.5rem] lg:w-28;
                img {
                    @apply rounded-lg object-contain;
                }
            }
            &-text {
                @apply gap-2 lg:gap-0;
            }
            &-title {
                @apply mb-1 text-left text-[28px] font-medium lg:text-[32px];
            }
            &-sub {
                @apply mb-3 text-left text-2xl lg:text-[28px];
            }
        }
    }

    &__main {
        @apply container mt-3 space-y-8 lg:mt-5;
        &-discounts {
            @apply space-y-3;
            h2 {
                @apply text-xl font-semibold text-gray-800;
            }
        }
    }
    &__allstores {
        @apply mb-5 mt-10 space-y-5 bg-white px-4 py-3 lg:container lg:rounded-xl;
        &-title {
            @apply mb-2 text-base font-semibold text-gray-800 lg:text-lg;
        }
        &-list {
            @apply grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4;
            &-item {
                &-name {
                    @apply mb-2 text-base text-gray-800 hover:underline lg:text-lg;
                }
            }
        }
    }
    &__footer {
        &-section {
            @apply mt-5 bg-white p-4 lg:container lg:rounded-xl;
        }
        &-head {
            @apply flex items-center gap-1.5;
            &-image {
                @apply self-center;
            }
            &-title {
                @apply mb-2 text-base font-semibold text-gray-800 lg:text-lg;
            }
        }
        &-description {
            @apply prose prose-gray max-w-none;
        }
    }
}
</style>
